


























































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { RegionalPassport } from "@/models/Indicator";
import { Region } from "@/models/Region";
import {
  REGION_REQUEST,
  DISTRICT_CREATE,
	DISTRICT_UPDATE,
	DISTRICT_DELETE,
  REGION_LOADED,
} from "@/store/actions/main";
import { Indicator } from "@/models/Indicator";
import {
  INDICATOR_REQUEST,
} from "@/store/actions/indicator";

@Component({})
export default class DistrictsTable extends Vue {
  private districts: any = [];
	private regions: any = [];
  private form: any = {};
  private districtsHeaders = [
    {
      text: "Наименование",
      value: "title",
      align: "start",
      width: "45%",
    },
    {
      text: "Название области",
      value: "name",
      align: "start",
      width: "45%",
    },
    {
      text: "Действия",
      value: "actions",
      align: "center",
      sortable: false,
      width: "10%",
    },
  ];
  private loading: boolean = false;
  private itemId: number = 0;
  private currentItem: any = {} as any;
  //Snackbar
  private messageText = "Не удалось выполнить";
  private messageColor = "red";
  private message = false;
  private search = "";
  private itemToDelete: any = {};
	private updateDialog: boolean = false;
	private createDialog: boolean = false;
  private deleteDialog: boolean = false;
  private deleteSnackbar: boolean = false;
  private deleteDisabled: boolean = false;
  //options
  private tablePage = 1;
  private tablePageCount = 0;
  private itemsPerPage = 15;
  //Initialise methods
  private initIfReady() {
    //return true if started to init self.
    let needReload = [] as boolean[];
		needReload.push(
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    );
    for (let i = 0; i < needReload.length; i++) {
      if (needReload[i] == true) {
        return false;
      }
    }
    console.log("TRUE INIT SELF");
    this.initSelf();
    return true;
  }
  private needReload(table, updated) {
    /*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
    if (table == null || !updated) return true;
    return false;
  }
  private tryToInitSelf() {
    this.loading = true;
    //const data = this.getParams();
    if (this.initIfReady()) return;
		if (
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    ) {
      this.$store.dispatch(REGION_REQUEST).then(() => {
        this.initIfReady();
      });
    }
  }
	private initSelf() {
    console.log("INIT SELF");
    //this.passports = this.$store.getters.INDICATOR_TABLE.data;
		this.regions = this.$store.getters.REGION_TABLE.data;
		//this.passports = JSON.parse(this.toString(localStorage.getItem("regional")));
    this.districts = this.currentItem.districts;
    this.loading = false;
    console.log("loading");
  }
  //Methods
	private toString(str) {
		return str as string;
	}
  private isDeleting(id) {
    if (this.itemToDelete.id == id) return true;
    return false;
  }
  private cleanDeleting() {
    this.itemToDelete = new Indicator();
  }
  private toCreate() {
		this.createDialog = true;
  }
  private toUpdate(item) {
		console.log("toUpdate", item);
		this.form.title = item.title;
		this.form.name = item.name;
		this.form.id = item.id;
		this.updateDialog = true;
	}
  //Methods
  // private deleteItem(id) {
  //   this.$store.dispatch(INDICATOR_DELETE, id).then(() => {
  //     this.tryToInitSelf();
  //   });
  // }
	private createItem(){
		this.loading = true;
		var converted = new FormData();
    Object.keys(this.form).forEach((key) => {
      if (typeof this.form[key] !== "undefined")
        converted.append(key, this.form[key]);
      else console.log("undefined:", key);
    });
		converted.delete("id");
		let data = {
			data: converted,
			params: {
				region_id: this.currentItem.id,
			},
		};
		this.$store
			.dispatch(DISTRICT_CREATE, data)
			.then(() => {
				this.messageText = "Область: " + this.form.title + " — добавлена.";
				this.messageColor = "green lighten-1";
			})
			.catch(() => {
				this.messageText = "Не удалось выполнить";
				this.messageColor = "red lighten-1";
			})
			.finally(() => {
				this.message = true;
				this.loading = false;
			});
	}
	private updateItem(){
		this.loading = true;
		var converted = new FormData();
    Object.keys(this.form).forEach((key) => {
      if (typeof this.form[key] !== "undefined")
        converted.append(key, this.form[key]);
      else console.log("undefined:", key);
    });
		converted.delete("id");
		let id = +this.form.id;
		let data = {
			data: converted,
			id: id,
		};
		
		this.$store
			.dispatch(DISTRICT_UPDATE, data)
			.then(() => {
				this.messageText = "Область: " + this.form.title + " — изменена.";
				this.messageColor = "green lighten-1";
			})
			.catch(() => {
				this.messageText = "Не удалось выполнить";
				this.messageColor = "red lighten-1";
			})
			.finally(() => {
				this.message = true;
				this.loading = false;
			});
	}
  private deleteItem(item: Indicator, confirmation: boolean) {
    this.itemToDelete = item;
    if (confirmation) {
      this.deleteDialog = true;
    } else {
      console.log(item);
      console.log("Delete! (not implemented)");
      // this.$store.dispatch(INDICATOR_DELETE, item.id).then(() => {
      //   this.tryToInitSelf();
      //   this.deleteSnackbar = true;
      //   this.itemToDelete = new Indicator();
      //   this.deleteDisabled = false;
      // });
      this.deleteDialog = false;
    }
  }
	private getRegion(id){
    for(let i = 0; i < this.regions.length; i++){
      if(id == this.regions[i].id)
        return this.regions[i].title
    }
  }
  //Hooks
	private created(){
		// if(localStorage.getItem("regionalIndicatorTitle")){
		// 	let title = localStorage.getItem("regionalIndicatorTitle");
		// 	this.$router.currentRoute.meta.breadcrumb[2].text = title;
		// }
    this.$store.dispatch(REGION_REQUEST).then(() => {
      let regions = this.$store.getters.REGION_TABLE.data;
      let data = this.toString(localStorage.getItem("currentRegion"));
      let currentItem = JSON.parse(data);
      this.currentItem = currentItem;
      for(let i = 0; i < regions.length; i++){
        if(regions[i].id == currentItem.id){
          this.$router.currentRoute.meta.breadcrumb[2].text = regions[i].indicator_title;
          this.$router.replace({name: "Districts", params: {id: currentItem.id}})
          this.currentItem = regions[i];
          break;
        }
      }
      this.tryToInitSelf();
    });
	}
  private mounted() {
    
  }
}
