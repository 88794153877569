class Indicator {
  constructor(
    public id?: number,
    public indicator_name?: string,
    public indicator_title?: string,
    public indicator_units?: string,
    public regional_passports?: RegionalPassport[],
    public federal_passport?: FederalPassport,
    public indicator_by_region?: IndicatorByRegion[],
  ) {
    this.indicator_name = "";
    this.indicator_title = "";
    this.indicator_units = "";
    this.indicator_name = indicator_name;
    this.indicator_title = indicator_title;
    this.indicator_units = indicator_units;
    this.regional_passports = regional_passports;
    this.federal_passport = federal_passport;
    this.id = id;
  }
}
//Таблица по регионам - Add/Update Region Value
class IndicatorByRegion {
  constructor(
    public indicator_id?: number,
    public region_id?: number,
    public year?: number,
    public indicator_value?: number,
  ) {
    this.indicator_id = indicator_id;
    this.region_id = region_id;
    this.year = year;
    this.indicator_value = indicator_value;
  }
}
//Таблица выводимых индикаторов
class IndicatorTable {
  constructor(
    public id?: number,
    public region_id?: number,
    public indicator_table_name?: string,
    public indicator_rows?: IndicatorTableRow[],
  ) {
    this.region_id = region_id;
    this.indicator_table_name = indicator_table_name;
    this.indicator_rows = indicator_rows;
  }
}
class IndicatorTableRow {
  constructor(
    public id?: number,
    public indicator_id?: number,
    public indicator_planned?: number,
    public indicator_actual?: number,
    public indicator_percent?: number,
  ) {
    this.indicator_id = indicator_id;
    this.indicator_planned = indicator_planned;
    this.indicator_actual = indicator_actual;
    this.indicator_percent = indicator_percent;
  }
}
class RegionalPassport {
  constructor(
    public id?: number,
    public indicator_id?: number,
    public region_id?: number,
    public title?: string,
    public date_update?: Date,
    public regulations?: string,
    public sources?: string,
    public method?: string,
    public additional_info?: string,
    public responsible?: string,
    public information_providers?: string,
    public files?: any[],
    public attachments?: any[],
  ) {
    this.id = id;
    this.indicator_id = indicator_id;
    this.region_id = region_id;
    this.title = title;
    this.date_update = date_update;
    this.regulations = regulations;
    this.sources = sources;
    this.method = method;
    this.additional_info = additional_info;
    this.responsible = responsible;
    this.information_providers = information_providers;
    this.files = files;
    this.attachments = attachments;
  }
}
/*
[
	{
		"id": 26,
		"indicator_name": "Указ_193_Количество высокопроизводительных рабочих мест во внебюджетном секторе экономики",
		"indicator_title": "Количество высокопроизводительных рабочих мест во внебюджетном секторе экономики",
		"indicator_units": "единица",
		"verified": false,
		"regional_passports": [
			{
				"id": 10,
				"indicator_id": 26,
				"region_id": 1,
				"title": "Наименование",
				"date_update": "2001-12-13T00:00:00",
				"regulations": "Регламент обновления",
				"sources": "Источники данных",
				"method": "Метод сбора данных",
				"additional_info": "Доп инфо",
				"responsible": "Ответственный",
				"information_providers": "Поставщики информации",
				"files": null,
				"attachments": [
					{
						"id": 7,
						"regional_passport_id": 10,
						"path": "uploads\\file_attachments\\0703093026_D2A1mI9ltenor.gif"
					},
				]
			}
		],
	}
]
*/
class FederalPassport {
  constructor(
    public id: number,
    public indicator_id: number,
    public units_in_words: string,
    public validity: string,
    public time_series_len_from: Date,
    public time_series_len_to: Date,
    public sources: string,
    public department: string,
    public responsible: string,
    public period: string,
    public features: string,
    public methodologies: string,
    public division: string,
    public placement: string,
  ) {
    this.id = id;
    this.indicator_id = indicator_id;
    this.units_in_words = units_in_words;
    this.validity = validity;
    this.time_series_len_from = time_series_len_from;
    this.time_series_len_to = time_series_len_to;
    this.sources = sources;
    this.department = department;
    this.responsible = responsible;
    this.period = period;
    this.features = features;
    this.methodologies = methodologies;
    this.division = division;
    this.placement = placement;
  }
}
export {
  Indicator,
  IndicatorByRegion,
  IndicatorTable,
  IndicatorTableRow,
  RegionalPassport,
  FederalPassport,
}